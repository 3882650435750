export default [
  {
    title: 'Home',
    i18n: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Nomination',
    i18n: 'Nomination',
    route: 'Nomination',
    icon: '',
    roles: ['admin'],
  },
  {
    title: 'NominationGeneralQuestions',
    i18n: 'NominationGeneralQuestions',
    route: 'NominationQuestionStages',
    icon: '',
    roles: ['admin'],
  },
  // {
  //   title: 'Regions',
  //   i18n: 'Regions',
  //   route: 'Regions',
  //   icon: '',
  //   roles: ['admin'],
  // },
  {
    title: 'Experiences',
    i18n: 'Experiences',
    route: 'Experiences',
    icon: '',
    roles: ['admin'],
  },
  {
    title: 'Varaints',
    i18n: 'QuestionVariants',
    route: 'QuestionVariantStages',
    icon: '',
    roles: ['admin'],
  },
  {
    title: 'Users',
    i18n: 'Users',
    route: 'Users',
    icon: '',
    roles: ['admin']
  },
  {
    title: 'Teachers',
    i18n: 'Teachers',
    route: 'Teachers',
    icon: '',
    roles: ['admin']
  },
  // {
  //   title: 'RequestesApplications',
  //   i18n: 'Applications',
  //   route: 'RequestesApplications',
  //   icon: '',
  //   roles: ['moderator','admin'],
  // },
  {
    title: 'NewApplications',
    i18n: 'NewApplicationsJury',
    route: 'NewApplications',
    icon: '',
    roles: ['moderator','admin'],
  },
  // {
  //   title: 'OnProgressApplications',
  //   i18n: 'OnProgressApplications',
  //   route: 'OnProgressApplications',
  //   icon: '',
  //   roles: ['moderator','admin'],
  // },
  {
    title: 'AcceptedApplications',
    i18n: 'AcceptedApplications',
    route: 'AcceptedApplications',
    icon: '',
    roles: ['moderator','admin'],
  },
  {
    title: 'RejectedApplications',
    i18n: 'RejectedApplications',
    route: 'RejectedApplications',
    icon: '',
    roles: ['moderator','admin'],
  },

  {
    title: 'ApplicationsMarks',
    i18n: 'ApplicationsMarks',
    route: 'ApplicationsMarks',
    icon: '',
    roles: ['moderator','admin'],
  },
  {
    title: 'NewApplicationsJury',
    i18n: 'NewApplicationsJury',
    route: 'NewApplicationsJury',
    icon: '',
    roles: ['jury',],
  },
  {
    title: 'ApplicationsJury',
    i18n: 'GradedApplications',
    route: 'ApplicationsJury',
    icon: '',
    roles: ['jury',],
  },

]
