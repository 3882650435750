<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
<!--    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">-->
<!--      <dark-Toggler class="d-none d-lg-block" />-->
<!--    </div>-->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
          id="dropdown-grouped"
          variant="link"
          class="dropdown-language"
          right
      >
        <template #button-content>
          <b-img
              :src="currentLocale.img"
              height="14px"
              width="22px"
              :alt="currentLocale.locale"
          />
          <span class="ml-50 text-body">{{ currentLocale.name }}</span>
        </template>
        <b-dropdown-item
            v-for="localeObj in locales"
            :key="localeObj.locale"
            @click="changeLocale(localeObj.locale)"
        >
          <b-img
              :src="localeObj.img"
              height="14px"
              width="22px"
              :alt="localeObj.locale"
          />
          <span class="ml-50">{{ localeObj.name }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >

        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{profile.lastname}} {{profile.firstname}}
            </p>
            <p>{{profile.username}}</p>
<!--            <span class="user-status">{{profile.roles.display_name}}</span>-->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="null"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logutUser">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"

          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { getUserData, removeAccessToken, removeRefreshToken, saveUserLang } from '@/libs/auth'
import {changeUserLocale} from '@/api/auth'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      locales: [
        {
          locale: 'uz',
          img: require('@/assets/images/flags/uz.png'),
          name: 'Uzbekcha',
        },
        {
          locale: 'ru',
          img: require('@/assets/images/flags/ru.png'),
          name: 'Русский',
        },
        {
          locale: 'en',
          img: require('@/assets/images/flags/en.png'),
          name: 'English',
        },
      ]
    }
  },
  computed:{
    profile() {
      return getUserData();
    },
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  methods:{
    logutUser() {
      this.$store.dispatch('auth/logoutTheUser').then(() => {
        window.location = '/login';
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        removeAccessToken();
        removeRefreshToken();
      })
    },
    changeLocale(locale) {
      saveUserLang(locale);
      this.$i18n.locale = locale;
      // changeUserLocale({lang:locale}).then(res => {
      //
      //   let user = getUserData()
      //   user.lang = res.data.data;
      //   setUserData(user);
      // })
    }
  }
}
</script>
